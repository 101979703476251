import { useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { initailizeVault } from "../../util/program/functions";
import { initialTierData } from "../../util/program/program";
import { enqueueSnackbar } from "notistack";
import Loader from "../Loader/loader";
import "../assests/css/index.css";

const UpdateTier = () => {
  const tierNames = ["Tier 0", "Tier 1", "Tier 2", "Tier 3"];

  const [tierData, setTierData] = useState(
    tierNames.map((tier, index) => initialTierData[index])
  );
  const [loading, setLoading] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const { connection } = useConnection();
  const wallet = useWallet();

  const handleChange = (e: any, index: number) => {
    const { name, value, type, checked } = e.target;
    const updatedTierData = [...tierData];
    updatedTierData[index] = {
      ...updatedTierData[index],
      [name]: type === "checkbox" ? checked : value,
    };
    setTierData(updatedTierData);
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!wallet.publicKey) {
      enqueueSnackbar("Need to connect wallet", {
        variant: "error",
        autoHideDuration: 1000,
      });
      return;
    }
    setLoading(true);
    console.log(tierData);
    await initailizeVault(connection, wallet, tierData);
    setLoading(false);
  };

  return (
    <div className="container">
      <h2 className="heading">{tierNames[currentStep]}</h2>
      <form onSubmit={handleSubmit} className="form">
        {/* Token Requirements */}
        <div className="formGroup">
          <label className="label">
            Token Requirement Min:
            <input
              type="number"
              name="tokenRequirement1"
              value={tierData[currentStep].tokenRequirement1}
              onChange={(e) => handleChange(e, currentStep)}
              required
              className="input"
            />
          </label>
        </div>

        <div className="formGroup">
          <label className="label">
            Token Requirement Max:
            <input
              type="number"
              name="tokenRequirement2"
              value={tierData[currentStep].tokenRequirement2}
              onChange={(e) => handleChange(e, currentStep)}
              required
              className="input"
            />
          </label>
        </div>

        <div className="formGroup">
          <label className="label">
            Commitment Time (in seconds):
            <select
              name="commitmentTime"
              value={tierData[currentStep].commitmentTime}
              onChange={(e) => handleChange(e, currentStep)}
              className="select"
            >
              <option value="1-month">1-month</option>
              <option value="6-month">6-month</option>
              <option value="12-month">12-month </option>
            </select>
          </label>
        </div>
        <div className="formGroup">
          <label className="label">
            Client Commission (%):
            <input
              type="number"
              name="clientCommission"
              value={tierData[currentStep].clientCommission}
              onChange={(e) => handleChange(e, currentStep)}
              required
              min={0}
              max={100}
              className="input"
            />
          </label>
        </div>
        <div className="formGroup">
          <label className="labelCheckbox">
            Access to Premium Nodes:
            <input
              type="checkbox"
              name="accessToPremiumNodes"
              checked={tierData[currentStep].accessToPremiumNodes}
              onChange={(e) => handleChange(e, currentStep)}
              className="checkbox"
            />
          </label>
        </div>

        <div className="formGroup">
          <label className="label">
            Referral Program:
            <select
              name="referralProgram"
              value={tierData[currentStep].referralProgram}
              onChange={(e) => handleChange(e, currentStep)}
              className="select"
            >
              <option value="true">True</option>
              <option value="false">False</option>
              <option value="Enhanced Rates">Enhanced Rates</option>
            </select>
          </label>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="buttons">
            {currentStep > 0 && (
              <button type="button" onClick={handlePrevious} className="button">
                Previous
              </button>
            )}
            {currentStep < tierNames.length - 1 ? (
              <button type="button" onClick={handleNext} className="button">
                Next
              </button>
            ) : null}
            {currentStep === 3 && (
              <button type="submit" className="button">
                Submit
              </button>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default UpdateTier;
