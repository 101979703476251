import Stake from "./components/Stake";
import Header from "./components/Header";
import Tabs from "./components/Form/tabs";
import UnStake from "./components/UnStake";
import { Route, Routes } from "react-router";
import { SnackbarProvider } from "notistack";
import HomePage from "./components/Home";
import ChangeVaultOwner from "./components/changeOwner";
import TierTable from "./components/Vault";

const App = () => {
  return (
    <div className="App">
      <SnackbarProvider
        iconVariant={{
          success: "✅",
          error: "✖️",
        }}
      />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/change-owner" element={<ChangeVaultOwner />} />
        <Route path="/admin" element={<Tabs />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/un-stake" element={<UnStake />} />
        <Route path="/vault" element={<TierTable />} />
      </Routes>
    </div>
  );
};

export default App;
