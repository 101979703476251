import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getOwnerAddress } from "../../util/program/functions";
const IMG = require("../assests/img/logo.jpeg");
const Header = () => {
  const [adminAccount, setAdminAccount] = useState("GWr5BZALAAGJQNrQMoTvpQ7GfMFjGDvnqabpE2TY9C8C")
  const { publicKey } = useWallet();
  const { connection } = useConnection();
  const wallet = useWallet();
  const getownerDetails = async () => {
    const address = await getOwnerAddress(connection, wallet)
    setAdminAccount(address)
  }

  useEffect(() => {
    getownerDetails()
  }, [])
  return (
    <header style={styles.header}>
      <div style={styles.logoContainer}>
        <img src={IMG} alt="Logo" style={styles.logo} />
      </div>
      <div style={styles.logoContainer}>
        <Link to={"/"} style={styles.title}>
          Home
        </Link>
      </div>
      {publicKey?.toBase58() === adminAccount && (
        <>
          <div style={styles.logoContainer}>
            <Link to={"/admin"} style={styles.title}>
              {" "}
              Vault
            </Link>
          </div>

          <div style={styles.logoContainer}>

            <Link to={"/change-owner"} style={styles.title}>
              {" "}
              Change Owner
            </Link>
          </div>
        </>
      )}

      <div style={styles.logoContainer}>
        <Link to={"/stake"} style={styles.title}>
          {" "}
          Stake
        </Link>
      </div>
      <div style={styles.logoContainer}>
        <Link to={"/vault"} style={styles.title}>
          {" "}
          Vault Details
        </Link>
      </div>

      <div style={styles.logoContainer}>
        <Link to={"/un-stake"} style={styles.title}>
          {" "}
          unStake
        </Link>
      </div>
      <div style={styles.walletButtonContainer}>
        <WalletMultiButton style={styles.walletButton} />
      </div>
    </header>
  );
};

const styles: any = {
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    width: "60%", // Half-width header
    margin: "0 auto", // Centering the header horizontally
    backgroundColor: "#f9f9f9",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid #ccc",
    borderRadius: "8px", // Optional: rounding the corners
  },
  logoContainer: {
    flex: "1",
    display: "flex",
    alignItems: "center",
  },
  logo: {
    height: "40px",
  },
  title: {
    flex: "2",
    textAlign: "center",
    fontSize: "22px",
    color: "#rgb(30 109 207 / 88%)",
    textDecoration: "none",
    
  },
  walletButtonContainer: {
    flex: "1",
    display: "flex",
    justifyContent: "flex-end",
  },
  walletButton: {
    padding: "10px 20px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#00c4e6",
    color: "#fff",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default Header;
