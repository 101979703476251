import { useCallback, useEffect, useState } from "react";
import {
  getVaultAccountData,
  getVaultData,
} from "../../util/program/functions";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import Loader from "../Loader/loader";

const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const { connection } = useConnection();
  const wallet = useWallet();
  const fetchData = useCallback(async () => {
    setLoading(true);

    const tiersD = await getVaultAccountData(connection, wallet);
    setData(tiersD);
    setLoading(false);

    // eslint-disable-next-line
  }, [wallet, connection]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="stake-container">
          <div className="countdown-timer">
            <h3>Total Staker profile</h3>
            <p>{data?.stakerCount || 0} </p>
          </div>
          <div className="countdown-timer">
            <h3>Total staked Tokens</h3>
            <p>{data?.totalStaked || 0} </p>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
