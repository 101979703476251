import "../assests/css/unstake.css";
import Loader from "../Loader/loader";
import CountdownTimer from "../Counter/Counter";
import { useState, useEffect, useCallback } from "react";
import { unStakeToken } from "../../util/program/unstaking";
import { getSTakerProfile } from "../../util/program/staking";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { closeStakerProfile } from "../../util/program/functions";
import { enqueueSnackbar } from "notistack";
const UnStake = () => {
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [Staked, setStaked] = useState<number>(0);

  const [claimableTokens, setClaimableTokens] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const { connection } = useConnection();
  const wallet = useWallet();
  const { publicKey } = useWallet();

  const getSTakerProfileResponse = async () => {
    if (!publicKey) {
      return;
    }
    setLoading(true);
    const res: any = await getSTakerProfile(connection, wallet);
    if (res) {
      setTotalStaked(res?.totalStakedToken?.toNumber()); // example value
      setStaked(res?.stakeAmount?.toNumber());
      setClaimableTokens(res?.claimAmount?.toNumber()); // example value
      const time = res?.endLockTime?.toNumber();
      setRemainingTime(time);
    } else {
      setRemainingTime(0);
    }

    setLoading(false);
  };

  const stakeTokenFunc = async () => {
    if (!publicKey) {
      enqueueSnackbar("Please connect your wallet", {
        variant: 'error', autoHideDuration: 1000
      })
      return
    }
    setLoading(true);

    await unStakeToken(connection, wallet);
    getSTakerProfileResponse();
    setLoading(false);
  };
  useEffect(() => {
    getSTakerProfileResponse();
  }, [publicKey]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="stake-container">
          <h2>Un Stake Your Tokens</h2>
          <button onClick={stakeTokenFunc} className="stake-button">
            un-stake Token
          </button>
          <button
            className="stake-button"
            onClick={() => {

              if (!publicKey) {
                enqueueSnackbar("Please connect your wallet", {
                  variant: 'error', autoHideDuration: 1000
                })
                return
              }
              closeStakerProfile(connection, wallet)
            }

            }      >
            {" "}
            Close Staker Account
          </button>
          <div>
            <CountdownTimer
              futureUnixTimestamp={remainingTime}
              claimableTokens={claimableTokens}
              totalStaked={totalStaked}
              Staked={Staked}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default UnStake;
