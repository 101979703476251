import React, { useState, useEffect } from "react";
import moment from "moment";
import "../assests/css/index.css";
const CountdownTimer: React.FC<{
  futureUnixTimestamp: number;
  totalStaked: number;
  claimableTokens: number;
  Staked: number;
}> = ({ futureUnixTimestamp, totalStaked, claimableTokens, Staked }) => {
  const [remainingTime, setRemainingTime] = useState<string>("");

  useEffect(() => {
    const updateRemainingTime = () => {
      if (futureUnixTimestamp === 0) {
        setRemainingTime("Not started Yet");
      }
      const now = moment();
      const futureDate = moment.unix(futureUnixTimestamp);
      const duration = moment.duration(futureDate.diff(now));

      if (duration.asSeconds() <= 0) {
        setRemainingTime("Time is up!");
        return;
      }

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setRemainingTime(
        `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`
      );
    };

    // Update time immediately
    updateRemainingTime();

    // Set interval to update time every second
    const intervalId = setInterval(updateRemainingTime, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [futureUnixTimestamp]);

  return (
    <>
      <div className="countdown-timer">
        <h3>Total Staked</h3>
        <p>{totalStaked}</p>
      </div>
      <div className="countdown-timer">
        <h3>Current Staked Tokens</h3>
        <p>{Staked}</p>
      </div>
      <div className="countdown-timer">
        <h3>Claimed Tokens</h3>
        <p>{claimableTokens}</p>
      </div>

      <div className="countdown-timer">
        <h3>Time Remaining</h3>
        <p>{remainingTime}</p>
      </div>
    </>
  );
};

export default CountdownTimer;
