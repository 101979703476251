import  { useState } from 'react';
import TierForm from './index';
import UpdateTier from "./update"
import "../assests/css/index.css"

const Tabs = () => {
    const [activeTab, setActiveTab] = useState('create');

    return (
        <div className="tabs-container">
            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'create' ? 'active' : ''}`}
                    onClick={() => setActiveTab('create')}
                >
                    Create
                </button>
                <button
                    className={`tab ${activeTab === 'update' ? 'active' : ''}`}
                    onClick={() => setActiveTab('update')}
                >
                    Update
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'create' && (
                    <div className="modal-content">
                        <TierForm />
                    </div>
                )}
                {activeTab === 'update' && (
                    <div className="modal-content">
                        <UpdateTier />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Tabs;
