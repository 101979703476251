import "../assests/css/unstake.css";
import Loader from "../Loader/loader";
import CountdownTimer from "../Counter/Counter";
import { useState, useEffect, useCallback } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getSTakerProfile, stakeToken } from "../../util/program/staking";
import { enqueueSnackbar } from "notistack";

const Stake = () => {
  const [amount, setAmount] = useState<number>(0);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [claimableTokens, setClaimableTokens] = useState<number>(0);
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [Staked, setStaked] = useState<number>(0);

  const { connection } = useConnection();
  const wallet = useWallet();
  const { publicKey } = useWallet();
  const getSTakerProfileResponse = useCallback(async () => {
    if (!publicKey?.toBase58()) {
      return;
    }
    setLoading(true);

    const res: any = await getSTakerProfile(connection, wallet);
    if (res) {
      setTotalStaked(res?.totalStakedToken?.toNumber()); // example value
      setStaked(res?.stakeAmount?.toNumber());
      setClaimableTokens(res?.claimAmount?.toNumber()); // example value
      const time = res?.endLockTime?.toNumber();
      setRemainingTime(time);
    } else {
      setRemainingTime(0);
    }
    setLoading(false);
  }, [publicKey?.toBase58()]);

  const stakeTokenFunc = async () => {
    if (!publicKey) {
      enqueueSnackbar("Please connect your wallet", {
        variant: 'error', autoHideDuration: 1000
      })
      return
    }
    setLoading(true);

    await stakeToken(connection, wallet, amount);
    getSTakerProfileResponse();
    setLoading(false);
  };
  useEffect(() => {
    if (publicKey) getSTakerProfileResponse();
  }, [publicKey?.toBase58()]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="stake-container">
          <h2>Stake Your Tokens</h2>
          <input
            type="number"
            value={amount}
            onChange={(e: any) => setAmount(Number(e.target.value))}
            placeholder="Enter amount"
            className="stake-input"
            width={"80%"}
            min={0}
          />
          <button onClick={stakeTokenFunc} className="stake-button">
            Stake Token
          </button>
          <div>
            <CountdownTimer
              futureUnixTimestamp={remainingTime}
              claimableTokens={claimableTokens}
              totalStaked={totalStaked}
              Staked={Staked}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Stake;
