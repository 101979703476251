import { useEffect, useState } from 'react';
import { getTokenPrice } from '../../util/program/functions';

const TokenPrice = () => {
    const [wifPrice, setWifPrice] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchPrice = async () => {
            const price = await getTokenPrice();
            setWifPrice(price);
            setLoading(false);
        };

        fetchPrice();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Token Price</h2>
            {wifPrice !== null ? (
                <p>The latest price of token is ${wifPrice.toFixed(2)} USD</p>
            ) : (
                <p>Failed to fetch the price.</p>
            )}
        </div>
    );
};

export default TokenPrice;
