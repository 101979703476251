import React, { useCallback, useEffect, useState } from 'react';
import "./vault.css"
import { initialTierData } from '../../util/program/program';
import { getVaultAccountData, getVaultData } from '../../util/program/functions';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import TokenPrice from './TokenPrice';
const TierTable = () => {
    const [initTierData, setIntiData] = useState(initialTierData)
    const [loading, setLoading] = useState(false);
    const { connection } = useConnection();
    const wallet = useWallet();
    const fetchData = useCallback(async () => {
        setLoading(true);

        const tiersD = await getVaultData(connection, wallet);
        setIntiData(tiersD);
        setLoading(false);

        // eslint-disable-next-line
    }, [wallet, connection]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return (
        <div className="stake-container">
            <div>
                <TokenPrice />
            </div>
            <div>

                <h2>Tier Data</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Token Requirement min $</th>
                            <th>Token Requirement max $</th>
                            <th>Commitment Time</th>
                            <th>Client Commission (%)</th>
                            <th>Access to Premium Nodes</th>
                            <th>Referral Program</th>
                        </tr>
                    </thead>
                    <tbody>
                        {initTierData.map((tier, index) => (
                            <tr key={index}>
                                <td>{tier.tokenRequirement1}</td>
                                <td>{tier.tokenRequirement2}</td>
                                <td>{tier.commitmentTime}</td>
                                <td>{tier.clientCommission}</td>
                                <td>{tier.accessToPremiumNodes ? "Yes" : "No"}</td>
                                <td>{tier.referralProgram}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TierTable;
