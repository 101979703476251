import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { changeOwner } from '../../util/program/functions'
import { useState } from 'react';
import Loader from '../Loader/loader';

const ChangeVaultOwner = () => {
    const { connection } = useConnection();
    const wallet = useWallet();
    const [newOwner, setNewOwner] = useState("")
    const [loading, setLoading] = useState(false);

    const onHandleSubmit = async () => {
        setLoading(true)
        await changeOwner(connection, wallet, newOwner)
        setLoading(false)

    }
    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <div className="stake-container">
                    <h2>Change Vault Owner</h2>
                    <input
                        type="string"
                        value={newOwner}
                        onChange={(e: any) => setNewOwner(e.target.value)}
                        placeholder="Enter new owner address"
                        className="stake-input"
                        width={"80%"}
                        min={0}
                    />
                    <button onClick={onHandleSubmit} className="stake-button">
                        Change owner
                    </button>
                </div>
            )}
        </>

    )
}

export default ChangeVaultOwner
